<!-- 待办事项 -->
<template>
  <div ref="todo" class="toDo common-block">
    <div class="common-title-n">
      <div class="common-title-l">
        <img src="../images/todo@2x.png" />
        <div>{{ $t("待办事项") }}</div>
      </div>
    </div>
    <!-- 代办数据项 -->
    <div ref="todoData" class="todoData">
      <div class="clue item">
        <div
          v-for="term in toDoList"
          :key="term.code"
          class="term"
          @click="goDeliverList(term)"
        >
          <div class="term-name">{{ term.code | filterCode }}</div>
          <div class="term-count">{{ term.finished }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { deliveryTodo } from '@/services/deliveryAssistant'
import loading from '@/utils/loading'
import calendarIcon from '@/images/calendar.svg'
import CalendarVue from './Calendar.vue'
import { mapGetters } from 'vuex'
import store from '@/store'
export default {
  filters: {
    filterCode(val) {
      const dictList = store.getters.dictHash[4012] || []
      return dictList.find(({ code }) => code === val)?.name
    },
  },
  components: {
    CalendarVue,
  },
  props: {
    commonParams: {
      type: [Object, String],
      default: {},
    },
    fromAssistantToDo: {
      type: Boolean,
      default: false,
    },
    toDoTime: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      calendarIcon,
      tableType: 'ALL',
      refName: 'calendar-i',
      toDoList: [],
      formData: {
        dealerId: '',
        roleCode: '',
      },
    }
  },
  computed: {
    ...mapGetters(['dictHash']),
  },
  watch: {
    commonParams: {
      handler(val) {
        this.getToDoList()
      },
      immediate: true,
    },
    fromAssistantToDo: {
      handler(val) {
        if (val) {
          if (this.toDoTime) {
            this.formData.planTime.start = dayjs(this.toDoTime).format(
              'YYYY-MM-DD 00:00:00'
            )
            this.formData.planTime.end = dayjs(this.toDoTime).format(
              'YYYY-MM-DD 23:59:59'
            )
          }
          this.$nextTick(() => {
            const scrollHeight = this.$refs.todo.offsetTop
            this.$refs.todo.parentElement.scrollTo(0, scrollHeight - 16)
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 获取代办列表数据
    async getToDoList() {
      this.toDoList = []
      loading.showLoading()
      try {
        const result = await deliveryTodo({
          ...this.formData,
          ...this.commonParams,
        })
        loading.hideLoading()
        this.toDoList = result
      } catch {
        loading.showLoading()
        this.toDoList = []
      }
    },
    // 跳转交付列表
    goDeliverList({ code }) {
      const { childCode = [], dealerIds = [], ...o } = this.commonParams
      this.$router.push({
        path: '/deliver-manage',
        query: {
          latestDeliveryTask: code,
          activeTab: 0,
          childCode: childCode.join(),
          dealerIds: dealerIds.join(),
          ...o,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "./common.less";
.toDo {
  display: flex;
  flex-direction: column;
  padding: 10px;
  .my-calendar {
    margin: 12px 0;
    /deep/ .van-calendar__body {
      overflow: hidden;
    }
    /deep/.dateList {
      .calendarBox {
        .calendar-i {
          .calendar-i-c {
            .today {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
  .todoData {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      font-size: 13px;
      box-sizing: border-box;
      border-radius: 4px;
      flex-wrap: wrap;
      .term {
        display: flex;
        flex-direction: column;
        min-height: 54px;
        width: 33%;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 12px;
        &:not(:nth-of-type(3n))::after {
          position: absolute;
          right: 0px;
          top: 15%;
          content: " ";
          height: 70%;
          width: 1px;
          background: rgba(13, 23, 26, 0.05);
          border-radius: 0px 0px 0px 0px;
        }
        .term-name {
          min-height: 22px;
          font-size: 12px;
          color: rgba(13, 23, 26, 0.75);
          flex: 1;
          padding: 0 4px;
        }
        .term-count {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 600;
          width: 100%;
        }
      }
    }
  }
}
</style>
