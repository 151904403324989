var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-calendar',{ref:"calendar",staticClass:"calendar",style:({
    height: _vm.fromPdi
      ? '180px'
      : _vm.isOpen
      ? _vm.maxHeight
      : `${134 - (_vm.isSubtitle ? 0 : 44)}px`,
  }),attrs:{"row-height":"60","default-date":_vm.planArriveDate ? new Date(_vm.planArriveDate) : null,"first-day-of-week":1,"poppable":false,"show-confirm":false,"formatter":_vm.formatter,"show-title":false,"show-subtitle":_vm.isSubtitle,"showMark":_vm.showMark,"minDate":new Date(_vm.minDate),"maxDate":new Date(_vm.maxDate)},on:{"month-show":_vm.monthShow,"select":_vm.selectDate},scopedSlots:_vm._u([{key:"bottom-info",fn:function(scope){return (_vm.isLoading)?[(_vm.getDot(scope))?_c('span',{staticClass:"dot"},[_vm._v(_vm._s(_vm.getDot(scope)))]):_vm._e()]:undefined}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }