<template>
  <div class="delivery-assistant">
    <!-- 头部搜索栏 -->
    <div class="header">
      <div class="name">{{ userInfo.name }}</div>
      <FilterComponent @goSearch="goSearch"></FilterComponent>
    </div>
    <div class="delivery-assistant-c">
      <!-- 方案 -->
      <Programme :commonParams="filterParams"></Programme>
      <!-- 代办事项 -->
      <ToDo :commonParams="filterParams" />

      <!-- 交付单 -->
      <DeliveryAppointment
        :commonParams="filterParams"
        @checkMore="(e) => $emit('checkMore', e, filterParams)"
      />
      <!-- 交付排行 -->
      <PerformanceRank
        :commonParams="filterParams"
        :userInfo="userInfo"
      ></PerformanceRank>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Programme from './components/Programme.vue'
import PerformanceRank from './components/PerformanceRank.vue'
import ToDo from './components/ToDo.vue'
import DeliveryAppointment from './components/DeliveryAppointment.vue'
import FilterComponent from './components/FilterComponent.vue'

export default {
  components: {
    Programme,
    ToDo,
    PerformanceRank,
    DeliveryAppointment,
    FilterComponent,
  },
  data() {
    return {
      fromAssistantToDo: false,
      filterParams: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === '/todo-list') {
        // 刷新待办列表和日历上的数据
        vm.$refs.todo.onRefresh()
        setTimeout(() => {
          const todoEle = document.getElementById('todo')
          if (todoEle) todoEle.scrollIntoView({ behavior: 'smooth' })
        }, 500)
      }
    })
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
  },
  methods: {
    onRefresh() {},
    // 头部查询
    goSearch(data) {
      this.filterParams = data
      this.onRefresh()
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  // box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  .name {
    padding-left: 16px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
  }
}
.delivery-assistant {
  color: @black;
  .delivery-assistant-c {
    padding: 12px 16px;
  }
  .common-block:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
</style>
