<!-- 待办事项 -->
<template>
  <div ref="todo" class="toDo common-block">
    <div class="common-title-n">
      <div class="common-title-l">
        <img src="../images/预约提车@2x.png" />
        <div>{{ $t("预约提车") }}</div>
      </div>
      <div class="common-title-r">
        <img :src="calendarIcon" @click.stop="showCalendar" />
      </div>
    </div>
    <div class="my-calendar">
      <WeekdayCalendar
        ref="weekdayCalendar"
        :init-date="getWeekdayDate"
        :commonParams="commonParams"
        @change="changeTime"
      />
    </div>
    <!-- 代办数据项 -->
    <!-- <List ref="list" :fetchApi="fetchApi">
			<template v-slot:default="{ item, index }">
				<deliver-item :dataSource="item" />
			</template>
		</List> -->
    <template v-if="dataList.length > 0">
      <deliver-item
        v-for="(item, index) in dataList"
        :key="index"
        :dataSource="item"
        @click.native="toDetails(item)"
      ></deliver-item>
      <p v-if="showMore" class="more" @click="checkMore">
        {{ $t("查看更多") }}
      </p>
    </template>
    <Empty v-else :description="$t('暂无数据')"></Empty>
    <!-- 日历弹窗 -->
    <van-popup
      v-model="showPopup"
      position="top"
      closeable
      :style="{ height: '70%' }"
    >
      <CalendarVue
        v-if="showPopup"
        ref="calendarvue"
        maxHeight="100%"
        :isRemove="false"
        :init-date="getWeekdayDate"
        @change="changeDate"
      />
    </van-popup>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import calendarIcon from '@/images/calendar.svg'
import WeekdayCalendar from './WeekdayCalendar.vue'
import CalendarVue from './Calendar.vue'
import List from '@/components/baseList/list'
import DeliverItem from './deliver-item'

export default {
  components: {
    WeekdayCalendar,
    CalendarVue,
    List,
    DeliverItem,
  },
  props: {
    commonParams: {
      type: [Object, String],
      default: {},
    },
  },
  data() {
    return {
      calendarIcon,
      showPopup: false,
      refName: 'calendar-i',
      formData: {
        queryType: 0,
        // deliveryStatuses: ['4000010'],
        bookDeliveryTime: {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59'),
        },
      },
      dataList: [],
      showMore: false,
    }
  },
  computed: {
    getWeekdayDate() {
      return this.formData.bookDeliveryTime.start
    },
  },
  watch: {
    commonParams: {
      handler(val) {
        this.$nextTick(() => {
          this.getList()
          this.$refs.weekdayCalendar.getToDoCalendar()
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 获取列表数据
    async getList(obj = {}) {
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        deliveryStatuses: ['4000010', '4000008', '4000006', '4000007'], // 已交车
        ...this.formData,
        ...this.commonParams,
      }
      loading.showLoading()
      const res = await deliveryServices.getDeliverList(params)
      loading.hideLoading
      const { dataList = [] } = res
      this.dataList = dataList
      if (this.dataList.length > 5) {
        this.showMore = true
        this.dataList = dataList.slice(0, 5)
      }
    },
    // 改变时间
    changeTime([start, end]) {
      this.formData.bookDeliveryTime.start = start
      this.formData.bookDeliveryTime.end = end
      this.getList()
    },
    // 改变日历选择
    changeDate(date) {
      this.showPopup = false;
      (this.formData.bookDeliveryTime.start = dayjs(date).format(
        'YYYY-MM-DD 00:00:00'
      )),
      (this.formData.bookDeliveryTime.end = dayjs(date).format(
        'YYYY-MM-DD 23:59:59'
      ))
      this.getList()
    },

    // 展示日历数据
    showCalendar() {
      this.showPopup = true
      this.$nextTick(() => {
        this.$refs.calendarvue.initCalendar()
      })
    },
    // 刷新列表和日历上的数据
    onRefresh() {
      this.getList()
      this.$nextTick(() => {
        this.$refs.weekdayCalendar.getToDoCalendar()
      })
    },
    // 查看更多
    checkMore() {
      this.$emit('checkMore', this.formData.bookDeliveryTime)
    },
    toDetails({ id }) {
      this.$router.push({
        path: '/deliver-detail',
        query: { id },
      })
    },
  },
}
</script>
<style lang="less" scoped>
@import "./common.less";
/deep/ .ul {
  padding-left: 0;
  padding-right: 0;
  .item:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
/deep/ .order-item {
  background: #f4f8fe;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
.toDo {
  padding: 10px;
  .my-calendar {
    margin: 12px 0;
    /deep/ .van-calendar__body {
      overflow: hidden;
    }
    /deep/.dateList {
      .calendarBox {
        .calendar-i {
          .calendar-i-c {
            .today {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
  .more {
    padding-top: 16px;
    text-align: center;
    color: @yellow-text;
  }
}
</style>
