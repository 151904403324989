<template>
  <div class="programme common-block">
    <div class="common-title-n">
      <div class="common-title-l">
        <img src="../images/工作概况@2x.png" />
        <div>{{ $t("工作概览") }}</div>
      </div>
    </div>

    <div
      class="delivered mt-12"
      :class="{ 'lang-other': !i18n.locale.includes('zh') }"
    >
      <div class="item" style="width: 25%;">
        <div class="label">{{ $t("交付量") }}</div>
      </div>
      <div
        class="item"
        :style="{
          width: '25%',
          cursor: 'pointer',
        }"
        @click="goDeliverList('today')"
      >
        <div class="label">{{ $t("日交付量") }}</div>
        <div class="count">{{ delivery[0].finished }}</div>
      </div>

      <div class="item" style="width: 25%;" @click="goDeliverList('week')">
        <div class="label">{{ $t("周交付量") }}</div>
        <div class="count">{{ delivery[1].finished }}</div>
      </div>
      <div class="item" style="width: 25%;" @click="goDeliverList('month')">
        <div class="label">{{ $t("月交付量") }}</div>
        <div class="count">{{ delivery[2].finished }}</div>
      </div>
    </div>
    <div class="not-delivered mt-12">
      <div class="left" style="width: 25%;">
        <div class="item">
          <div class="label">{{ $t("留存量") }}</div>
          <div
            class="count"
            style="font-weight: 600;font-size: 16px;color: #0D171A;"
          >
            {{ total.finished }}
          </div>
        </div>
      </div>
      <div class="right">
        <div
          v-for="(item, index) in toFinish"
          :key="index"
          class="item"
          @click="goDeliverList(item)"
        >
          <div class="label">{{ item.name }}</div>
          <div class="count">{{ item.finished }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ROLES } from '@/constants/constants'
import { deliveryWorkOverview } from '@/services/deliveryAssistant'
import loading from '@/utils/loading'
import { i18n } from '@/main'

export default {
  props: {
    commonParams: {
      type: [Object, String],
      default: {},
    },
  },
  data() {
    return {
      i18n,
      delivery: [
        {
          name: '日交付量',
          nameEn: 'Today',
          finished: 0,
          target: '',
        },
        {
          name: '周交付量',
          nameEn: 'Week',
          finished: 0,
          target: '',
        },
        {
          name: 'Month',
          finished: 0,
          target: '',
        },
      ],
      total: {
        name: '留存总计',
        nameEn: 'Total',
        finished: 0,
        target: '',
      },
      toFinish: [
        {
          name: '未排产',
          nameEn: 'To product',
          finished: 0,
          target: '',
        },
        {
          name: '已排产',
          nameEn: 'Producing',
          finished: 0,
          target: '',
        },
        {
          name: '已下线',
          nameEn: 'Ready for delivery',
          finished: 0,
          target: '',
        },
        {
          name: '已发运',
          nameEn: 'Dispatched',
          finished: 0,
          target: '',
        },
        {
          name: '已入库',
          nameEn: 'Arrived in store',
          finished: 0,
          target: '',
        },
        {
          name: '已PDI',
          nameEn: 'Pdi confirmed',
          finished: 0,
          target: '',
        },
        {
          name: '已整备',
          nameEn: 'Vehicle prepared',
          finished: 0,
          target: '',
        },
        {
          name: '休眠',
          nameEn: 'Dormancy',
          finished: 0,
          target: '',
        },
      ],
      ROLES,
      formData: {
        dealerId: '',
        roleCode: '',
      },
    }
  },

  watch: {
    commonParams: {
      async handler(val) {
        this.getOverView()
      },
      immediate: true,
    },
  },
  methods: {
    // 获取概览数据
    async getOverView() {
      loading.showLoading()
      const result = await deliveryWorkOverview({
        ...this.formData,
        ...this.commonParams,
      })
      loading.hideLoading()
      this.targetStats = []
      this.targetStatsAll = []
      const { code, data, msg } = result
      if (code === 0) {
        this.delivery = data.delivery
        this.toFinish = data.toFinish
        this.total = data.total
      } else {
        this.$toast(msg)
      }
    },

    // 跳转
    goDeliverList(item) {
      const { childCode = [], dealerIds = [], ...o } = this.commonParams
      let queryObj = {
        childCode: childCode.join(),
        dealerIds: dealerIds.join(),
        ...o,
      }
      if (typeof item === 'object') {
        queryObj.queryTypeCode = item.code
      } else {
        queryObj.deliverTimeType = item
      }
      this.$router.push({
        path: '/deliver-manage',
        query: queryObj,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.mt-12 {
  margin-top: 12px;
}
@import "./common.less";
.programme {
  overflow: hidden;
  font-size: 13px;
  color: @black;
  padding: 12px;
  /deep/ .van-swipe-item {
    width: 100%;
    background: #ffffff;
    height: auto;
    padding: 6px;
    &:first-of-type {
      flex-direction: column;
    }
  }
  .label {
    line-height: 22px;
    color: rgba(13, 23, 26, 0.75);
  }
  .count {
    line-height: 22px;
    color: #0d171a;
  }
  .delivered {
    display: flex;
    width: 100%;
    min-height: 52px;
    background: linear-gradient(
      180deg,
      #f0f4fc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 4px;
    &.lang-other {
      padding-top: 8px;
      .month,
      .week {
        display: flex;
        flex-direction: column;
      }
      .label {
        line-height: 1;
      }
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:first-child {
        padding-left: 12px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 8px;
          right: 12px;
          bottom: 8px;
          width: 1px;
          background: rgba(13, 23, 26, 0.05);
        }
      }
      .week,
      .month {
        cursor: pointer;
      }
    }
  }
  .not-delivered {
    display: flex;
    background: linear-gradient(
      180deg,
      #f0f4fc 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 4px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 12px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        right: 12px;
        bottom: 8px;
        width: 1px;
        background: rgba(13, 23, 26, 0.05);
      }
    }
    .right {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .item {
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25%;
        &:first-child {
          width: 25%;
        }
      }
    }
  }
}
</style>
