<template>
  <div id="performanceRank" class="performanceRank common-block">
    <div class="common-title-n">
      <div class="common-title-l">
        <img src="../images/交付排行@2x.png" />
        <div>{{ $t("交付排行") }}</div>
      </div>
      <div v-if="activeTab == 'RANK_DELIVERY'" class="common-title-r">
        <div class="time">
          <div
            class="week"
            :class="{ active: dataText == 'today' }"
            @click="onDateShortcut('today')"
          >
            {{ $t("今日") }}
          </div>
          <div
            class="week"
            :class="{ active: dataText == 'week' }"
            @click="onDateShortcut('week')"
          >
            {{ $t("本周") }}
          </div>
          <div
            :class="{ active: dataText == 'month' }"
            class="month"
            @click="onDateShortcut('month')"
          >
            {{ $t("本月") }}
          </div>
        </div>
      </div>
    </div>
    <van-tabs v-model="activeTab" :ellipsis="false">
      <van-tab
        v-for="(item, index) in tabList"
        :key="index"
        :title="item.title"
        :name="item.value"
      >
        <div v-if="item.value == 'RANK_DELIVERY'" class="rankContent">
          <div
            v-for="data in dataList"
            :key="data.userId"
            :class="[
              'rankItem',
              data.empId === userInfo.id ||
              data.empName === userInfo.name + $t('组')
                ? 'active'
                : '',
            ]"
          >
            <div class="ranking">
              <img
                v-if="[1, 2, 3].includes(data.rank)"
                :src="getUrl[data.rank - 1]"
              />
              <div v-else>{{ data.rank }}</div>
            </div>
            <div class="name">
              {{ data.empName }}
              <!-- {{ data.empId == userInfo.id ? $t('我') : data.empName }} -->
            </div>
            <div class="count">{{ data.count }}</div>
          </div>
          <div v-if="dataListAll.length > 10" class="more" @click="onShow">
            {{ showMore ? $t("收起") : $t("展开") }}
            <van-icon v-if="showMore" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </div>
          <div
            v-if="query && dataList.length == 0"
            class="common-noData"
            style="height: 212px;"
          >
            {{ $t("暂无数据!") }}
          </div>
        </div>
        <Empty v-else :description="$t('暂无来源')"></Empty>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import goldIcon from '@/images/gold_icon.png'
import silverIcon from '@/images/silver_icon.png'
import bronzeIcon from '@/images/bronze_icon.png'
import dayjs from 'dayjs'
import { deliveryRank } from '@/services/deliveryAssistant'
import loading from '@/utils/loading'
export default {
  props: {
    commonParams: {
      type: Object,
      default: () => ({}),
    },
    userInfo: {
      type: [Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      goldIcon,
      silverIcon,
      bronzeIcon,
      query: false,
      dataText: '',
      tabList: [
        { value: 'RANK_DELIVERY', title: this.$t('交付量') },
        { value: 'RANK_ORDER', title: this.$t('满意度') },
      ],
      activeTab: 'RANK_DELIVERY',
      dataList: [],
      dataListAll: [],
      getUrl: [goldIcon, silverIcon, bronzeIcon],
      showMore: false,
      formData: {
        rankType: 1,
        statCode: 'RANK_DELIVERY',
        rankDateType: 3,
        statisticsTime: {
          start: '',
          end: '',
        },
      },
    }
  },
  watch: {
    commonParams: {
      handler() {
        this.getRankInfo()
      },
      deep: true,
    },
  },
  mounted() {
    this.onDateShortcut('month', true)
  },
  methods: {
    onShow() {
      if (!this.showMore) {
        this.dataList = this.dataListAll
      } else {
        this.dataList = this.dataListAll.slice(0, 10)
      }
      this.showMore = !this.showMore
    },
    // 计算当前周
    getCurrentWeek() {
      const start = this.$moment()
        .isoWeekday(1)
        .format('YYYY-MM-DD 00:00:00') // 本周一
      const end = this.$moment()
        .isoWeekday(7)
        .format('YYYY-MM-DD 23:59:59') // 本周日
      this.formData.statisticsTime = {
        start,
        end,
      }
    },
    // 按日期查询
    onDateShortcut(type, load = true) {
      this.dataText = type
      if (type == 'today') {
        this.formData.statisticsTime = {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59'),
        }
        this.formData.rankDateType = 1
      } else if (type == 'week') {
        this.formData.rankDateType = 2
        this.getCurrentWeek()
      } else {
        const start = this.$moment()
          .startOf(type)
          .format('YYYY-MM-DD 00:00:00')
        const end = this.$moment()
          .endOf(type)
          .format('YYYY-MM-DD 23:59:59')
        this.formData.rankDateType = 3
        this.formData.statisticsTime = {
          start,
          end,
        }
      }
      load && this.getRankInfo()
    },
    // 获取排行榜数据
    async getRankInfo() {
      loading.showLoading()
      const result = await deliveryRank({
        ...this.formData,
        ...this.commonParams,
      })
      this.query = true
      this.showMore = false
      this.dataList = []
      this.dataListAll = []
      const { code, data = [], msg } = result
      if (code === 0) {
        this.dataListAll = data
        if (data.length > 10) {
          this.dataList = data.slice(0, 10)
        } else {
          this.dataList = data
        }
      } else {
        this.$toast(msg)
      }
      loading.hideLoading()
    },
  },
}
</script>
<style lang="less" scoped>
@import "./common.less";
.performanceRank {
  padding: 10px;
  height: auto;
  /deep/ .van-dropdown-menu {
    .van-dropdown-menu__bar {
      height: 22px;
      background: none;
      box-shadow: none;
      .van-dropdown-menu__item {
        justify-content: flex-start;
      }
      .van-dropdown-menu__title {
        font-size: 13px;
      }
      .van-dropdown-menu__title::after {
        border-color: transparent transparent @black @black;
      }
    }
  }
  /deep/ .popoverBox {
    &:not(:last-child) {
      margin-right: 8px;
    }
    .textShow {
      width: fit-content;
      .van-icon-play {
        transform: rotate(90deg);
      }
    }
  }
  /deep/.rankContent {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 212px;
    .rankItem {
      display: flex;
      font-size: 13px;
      height: 40px;
      padding: 8px 12px;
      margin-bottom: 8px;
      align-items: center;
      &:first-of-type {
        margin-top: 16px;
      }
      &.active {
        background: rgba(238, 212, 132, 0.1);
        border-radius: 4px;
        border: 1px solid rgba(238, 212, 132, 0.45);
      }
      .ranking {
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .name {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .count {
        width: 80px;
        text-align: right;
      }
    }
    .more {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(13, 23, 26, 0.45);
      font-size: 12px;
    }
  }
}
/deep/ .van-popover__action {
  width: fit-content;
}
</style>
