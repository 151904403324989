<template>
  <div ref="filterBox" class="filterBox">
    <van-popover
      v-model="showPopover"
      theme="dark"
      placement="right-start">
      <div class="stageTips" style="width: 230px;" >
        <p>{{ $t('请选择筛选条件后，点击‘查询’按钮筛选数据，') }}</p>
        <p>{{ $t('大区/城市区域/交付门店有联动关系；') }}</p>
        <p>{{ $t('门店省份/门店城市/交付门店有联动关系；') }}</p>
        <p>{{ $t('但区域与门店省份城市不联动，支持按区域或省份城市查询账号权限范围内的数据。') }}</p>
      </div>
      <template #reference>
        <van-icon name="question-o" size="16px" color="#B9921A" @click.stop="showPopover=true"/>
      </template>
    </van-popover>
    <div class="filter_content">
      <van-dropdown-menu active-color="#B9921A">
        <van-dropdown-item v-model="parentCode" :options="parentList" get-container="body" :title="parentTitle" @change="handleChange($event, 'parent')">
        </van-dropdown-item>
        <van-dropdown-item v-model="childCode" :options="childList" get-container="body" :title="childTitle" @change="handleChange($event, 'child')"/>
        <van-dropdown-item v-model="dealerProvinceCode" :options="provinceList" get-container="body" :title="provinceTitle" @change="handleChange($event, 'province')"/>
        <van-dropdown-item v-model="dealerCityCode" :options="cityList" get-container="body" :title="cityTitle" @change="handleChange($event, 'city')"/>
        <van-dropdown-item v-model="dealerIds" :options="dealerList" get-container="body" :title="dealerTitle" @change="handleChange($event, 'dealer')"/>
      </van-dropdown-menu>
    </div>
    <div class="search" @click="goSearch">{{ $t('查询') }}</div>
  </div>
</template>
<script>
import salesAssistantService from '@/services/salesAssistant'
import { debounce } from 'lodash'
export default {
  name: 'FilterComponent',
  data() {
    return {
      showPopover: false,
      parentCode: '', // 大区code
      parentTitle: this.$t('大区'),
      childTitle: this.$t('城市区域'),
      provinceTitle: this.$t('门店省份'),
      cityTitle: this.$t('门店城市'),
      dealerTitle: this.$t('交付门店'),
      titleObj: {
        parentTitle: this.$t('大区'),
        childTitle: this.$t('城市区域'),
        provinceTitle: this.$t('门店省份'),
        cityTitle: this.$t('门店城市'),
        dealerTitle: this.$t('交付门店'),
      },
      parentList: [
        { text: this.$t('全部'), value: '' },
      ], // 大区列表
      childCode: '', // 城市区域
      childList: [
        { text: this.$t('全部'), value: '' }  
      ], // 城市区域列表
      dealerProvinceCode: '', // 省份
      provinceList: [
        { text: this.$t('全部'), value: '' }
      ], // 省份列表
      dealerCityCode: '', // 城市
      cityList: [
        { text: this.$t('全部'), value: '' }
      ], // 城市列表
      dealerIds: '', // 交付门店
      dealerList: [
        { text: this.$t('全部'), value: '' }
      ] // 门店列表
    }
  },
  computed: {},
  watch: {
    // 监听大区变化
    parentCode: {
      handler(val) {
        this.childCode = ''
        this.childTitle = this.$t('城市区域')
        this.dealerIds = ''
        this.dealerTitle = this.$t('交付门店')
        this.dealerList = [{ text: this.$t('全部'), value: '' }]
        this.getRgeionPage(val)
        this.getDealers({ parentCode: val, childCode: [], provinceCode: this.dealerProvinceCode, cityCode: this.dealerCityCode })
      },
      deep: true
    },
    // 监听小区变化
    childCode: {
      handler(val) {
        this.dealerIds = ''
        this.dealerTitle = this.$t('交付门店')
        this.dealerList = [{ text: this.$t('全部'), value: '' }]
        this.getDealers({ parentCode: this.parentCode, childCode: val ? [val] : [], provinceCode: this.dealerProvinceCode, cityCode: this.dealerCityCode })
      },
      deep: true
    },
    // 监听门店省份变化
    dealerProvinceCode: {
      handler(val) {
        this.dealerCityCode = ''
        this.cityTitle = this.$t('门店城市')
        this.cityList = [{ text: this.$t('全部'), value: '' }]
        this.dealerIds = ''
        this.dealerTitle = this.$t('交付门店')
        this.dealerList = [{ text: this.$t('全部'), value: '' }]
        this.getProvinceCity(val || 0)
        this.getDealers({ parentCode: this.parentCode, childCode: this.childCode ? [this.childCode] : [], provinceCode: val, cityCode: this.dealerCityCode })
      },
      deep: true
    },
    // 监听门店城市变化
    dealerCityCode: {
      handler(val) {
        this.dealerIds = ''
        this.dealerTitle = this.$t('交付门店')
        this.dealerList = [{ text: this.$t('全部'), value: '' }]
        this.getDealers({ parentCode: this.parentCode, childCode: this.childCode ? [this.childCode] : [], provinceCode: this.dealerProvinceCode, cityCode: val })
      },
      deep: true
    },
    // // 监听交付门店变化
    // dealerIds: {
    //   handler(val) {

    //   },
    //   deep: true
    // }
  },
  async mounted() {
    await this.getRegionTree()
    await this.getProvinceCity()
    this.getDealers({ parentCode: '', childCode: [], provinceCode:'', cityCode: '' })
  },
  methods: {
    goSearch() {
      const filterParams = {
        parentCode: this.parentCode,
        childCode: this.childCode ? [this.childCode] : [],
        dealerProvinceCode: this.dealerProvinceCode,
        dealerCityCode: this.dealerCityCode,
        dealerIds: this.dealerIds ? [this.dealerIds] : [],
      }
      this.$emit('goSearch', filterParams)
    },
    // 获取大区
    async getRegionTree() {
      this.parentList = [{ text: this.$t('全部'), value: '' }]
      const res = await salesAssistantService.getRegionTreeApi()
      const result = res.map((item) => { return { ...item, text: item.name, value: item.code } })
      this.parentList = this.parentList.concat(result)
      if (result && result.length === 1) {
        this.parentCode = result[0].value
        this.handleChange(result[0].value, 'parent')
        this.goSearch()
      }
    },
    // 获取小区
    async getRgeionPage(parentCode) {
      if (!parentCode) return false
      this.childList = [{ text: this.$t('全部'), value: '' }]
      const res = this.parentList.find(({ code }) => { return code === parentCode })?.children || []
      const result = res.map((item) => { return { ...item, text: item.name, value: item.code } })
      this.childList = this.childList.concat(result)
      if (result && result.length === 1) {
        this.childCode = result[0].value
      }
      if (this.childCode) {
        this.handleChange(this.childCode, 'child')
        this.goSearch()
      }
    },
    // 获取省市数据
    async getProvinceCity(parentId=0) {
      const res = await salesAssistantService.getPronceApi({ parentId })
      const result = res.map((item) => { return { ...item, text: item.name, value: item.code } })
      if (parentId === 0) {
        this.provinceList = [{ text: this.$t('全部'), value: '' }]
        this.provinceList = this.provinceList.concat(result)
      } else {
        this.cityList = [{ text: this.$t('全部'), value: '' }]
        this.cityList = this.cityList.concat(result)
      }
    },
    // 获取交付门店数据
    // async getDealers(query) {
    //   const params = {
    //     pageNum: 1,
    //     pageSize: 100000,
    //     ...query
    //   }
    //   this.dealerList = [{ text: this.$t('全部'), value: '' }]
    //   const res = await salesAssistantService.getRegionPageApi(params)
    //   const { dataList=[] } = res
    //   const result = dataList.map((item) => { return { code: item.id, name: item.name, text: item.name, value: item.id } })
    //   this.dealerList = this.dealerList.concat(result)
    // },
    getDealers: debounce (async function(query){
      const params = {
        pageNum: 1,
        pageSize: 100000,
        ...query,
        dealerType: 2
      }
      this.dealerList = [{ text: this.$t('全部'), value: '' }]
      const res = await salesAssistantService.getRegionPageApi(params)
      const { dataList=[] } = res
      const result = dataList.map((item) => { return { code: item.id, name: item.name, text: item.name, value: item.id } })
      this.dealerList = this.dealerList.concat(result)
    }, 500),
    handleChange(code, name) {
      if (!code) {
        this[`${name}Title`] = this.titleObj[`${name}Title`]
      } else {
        const item = this[`${name}List`].find(({ value }) => { return value === code })
        this[`${name}Title`] = item.name
      }
    }
  }
}
</script>
<style>
.stageTips{
  padding: 12px;
  font-size: 12px;
  color: #fff;
  border-radius: 8px;
  width: 230px
}
.van-dropdown-item__content .van-cell__title{
  font-size: 13px;
}
</style>
<style lang="less" scoped>
.filterBox{
  display: flex;
  align-items: center;
  height: 48px;
  background: #fff;
  overflow: hidden;
  padding-left: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 48px;
  z-index: 10;
  .filter_content{
    flex: 1;
    display: flex;
    height: 48px;
    overflow: hidden;
    background: #fff;
    /deep/ .van-dropdown-menu{
      flex: auto;
      overflow-x: scroll;
      overflow-y: hidden;
      background: #fff;
      .van-dropdown-menu__bar{
        min-width: fit-content;
        .van-dropdown-menu__item{
          flex: auto;
          margin-right: 6px;
          &:not(:last-of-type) {
            // margin-right: 6px;
          }
          .van-dropdown-menu__title{
            width: fit-content;
            display: inline-block;
            font-size: 13px;
            .van-ellipsis{
              width: fit-content;
            }
          }
        }
      }
    }
  }
  .search{
    width: fit-content;
    color: @yellow-text;
    cursor: pointer;
    padding: 0 8px 0 8px;
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>